module.exports = {
  version: "1.0.9",
  default_image_url: "/images/resized/everblue_placeholder.png",
  available_page_sizes: [20, 50, 100],
  defaultPageLength: 20,
  mapsKey: "AIzaSyB451F91Eed54R7ToFu8_tzH-0IMyN66YI",
  color_attribute_id: "93",
  website_id: 1,
  tax: 1.2,
  contentSecurityPolicy: {
    directives: {
      scriptSrc: ["www.googletagmanager.com"],
      frameSrc: [],
      styleSrc: [],
      imgSrc: [
        "preprod-m2.everblue.ethersys.host",
        "placehold.it",
        "*.placeholder.com",
        "placeholder.com",
      ],
      connectSrc: ["*.google-analytics.com"],
      baseUri: ["preprod-m2.everblue.ethersys.host"],
    },
  },
  homepage_identifier: "home",
  defaultTitle:
    "EVERBLUE l Pisciniste - construction, rénovation - Excellence et Sérénité",
  defaultDescription:
    "Everblue, votre réseau de piscinistes et pisciniers, leader dans la construction et la rénovation de piscines en béton sur-mesure.",
  themeColor: "#fbb03b",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [],
    attributeFacetMinimumDocumentCount: 1,
    authorizedCategoriesFacet: [],
    categoryFacetMinimumDocumentCount: 1,
  },
  phoneNumber: "01 02 03 04 05",
  email: "equipe@occitech.fr",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
  sentryDsn: "https://61c707ad67ee4cbc9cba45ed184123b0@sentry.occitech.fr/18",
  sentryEnabled: true,
  sentryEnvironment: "preprod",
  categoriesIds: {
    featured: 10,
    new: 652,
    discount: 653,
  },
  parentCategoryId: 2,
  url: process.env.FRONT_COMMERCE_MAGENTO_ENDPOINT,
  guestPaths: [
    "/login",
    "/account/password-request",
    "/account/password-reset",
  ],
  disabledPagePaths: ["/register", "/cart", "/user/wishlist"],
  vatTax: 1.2,
};
